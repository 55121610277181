import React from 'react'
import styled from 'styled-components'
import {
  Flex,
  MOBILE_ONLY_CSS_WRAPPER,
  TABLET_AND_ABOVE_CSS_WRAPPER,
  TABLET_ONLY_CSS_WRAPPER,
  DESKTOP_ONLY_CSS_WRAPPER,
  Text,
  Vis
} from './common'

const Dot = styled(({ state = 'active', className = '', ...props }) => <Flex flex="nogrow center" className={`${className} ${state}`} {...props}><Flex flex="nogrow" className="innerDot" /></Flex>)`
height:12px;width:12px;
border-radius:6px;
${MOBILE_ONLY_CSS_WRAPPER('margin: 0px 2px;')}
${TABLET_AND_ABOVE_CSS_WRAPPER('margin: 0px 5px;')}
box-sizing: border-box;
border: 1px solid transparent;

&.pastactive {
  .innerDot { background: #00B398; }
}
&.active {
  border-color: #A5D867;
  .innerDot { background: #00B398; }
}
&.inactive {
  .innerDot { background: #00B398; }
}
.innerDot {
  height:6px;width:6px;
  border-radius:3px;
}
`

const Dash = styled(Flex)`
height:1px;
box-sizing: border-box;
background: #373737;
opacity: 0.75;
`

export const Breadcrumb = styled((props) => {
  let { from = 1, to = 6, ...outerProps } = props
  from = parseInt(from)
  to = parseInt(to)
  from = Math.max(1, from)
  to = to >= from ? to : from
  const toMinOne = to - 1
  const fromMinOne = from - 1
  const dots = new Array(to).fill(1)
  // console.debug(`Drawing breadcrumb`, { from, to, dots })
  return (<Flex flex="col">
    <Flex flex="row">
      <Flex flex="row vcenter" {...outerProps}>
        {dots.map((dot, idx) => {
          return <React.Fragment key={idx}>
            <Dot key={idx} state={idx === fromMinOne ? 'active' : idx < fromMinOne ? 'pastactive' : 'inactive'} />
            {idx < toMinOne && <Dash />}
          </React.Fragment>
        })}
      </Flex>
    </Flex>
    <Vis.NonMobile flex="row hcenter" style={{ paddingTop: '10px', color: '#373737', opacity: '0.75', fontFamily: 'Arial' }}><Text.Steps>Step {from} of {to}</Text.Steps></Vis.NonMobile>
  </Flex>)
})`

${MOBILE_ONLY_CSS_WRAPPER('margin: 0px 10px;')}
${TABLET_ONLY_CSS_WRAPPER('margin: 0px 60px;')}
${DESKTOP_ONLY_CSS_WRAPPER('margin: 0px auto;max-width: 510px;')}
`