import React from "react"
import styled from 'styled-components'
import {
  Button, ButtonGreen, Flex, ButtonOutline,
  GreenBarSection, HeaderBanner,
  Text, WhiteSection, FooterBanner, LightBlueSectionNoMargin, Vis
} from '../components/common'
import Layout from "../components/layout"
import { Breadcrumb } from "../components/Breadcrumb"
import CongratsHeroSvg from '../images/view_data.svg'
import api from '../utils/api'
import { useQuery } from 'react-query'
import ViewDataSection from '../pages/viewdata-11'
import { navTo } from '../utils/navigation'
import util from '../utils/utils'

const ImageHolder = styled.img`
  width: 90%;
  object-fit: contain;
`

const ErrorBootstrapSection = styled.div`
  margin: 20px 18px;
`

const DarkGreenButton = styled(Button)`
  color: #373737;
  background: #FFFFFF;
  border-radius: 9px;
  font-family: 'Arial';
  /* animation */
  position: relative;
  overflow: hidden;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  &:hover{
    color: #FFFFFF;
    background: #373737;
  }
  &:after {
      content: "";
      background: rgba(0, 66, 77, 0.12);
      display: block;
      position: absolute;
      padding: 100%;
      opacity: 0;
      transition: all 0.8s;
      border-radius: 50%;
    }
    
  &:active:after {
      padding: 0;
      margin: 0;
      opacity: 1;
      transition: 0s
  }
`

const ButtonDelay = (navigateFn, payload) => {
  setTimeout(() => {
    if (payload) {
      navigateFn(payload)
    } else {
      navigateFn()
    }
  }, 300)
}


const Congrats = (props) => {
  let { location: { state = {} } = {} } = props
  if (state === null) state = {}
  const { bank = null } = state
  const bankUri = bank === null ? null : bank.logoUri
  const bankTitle = bank === null ? null : bank.brandName

  const userDetail = api.useGetProMember()
  const [showData, setShowData] = React.useState(false)
  const [summaryData, setSummaryData] = React.useState({})
  const [clickedShowData, setClickShowData] = React.useState(false)
  const [cdrDashboardUrl, setCdrDashboardUrl] = React.useState('https://mycdr.uat.cds.cuscal.com.au/')

  const downloadData = () => {
    if (util.isMobile()) {
      window.open('/api/pdf?device=mobile', '_blank');
    } else {
      window.open('/api/pdf', '_blank');
    }
    setClickShowData(true)
  }

  const downloadJSONData = () => {
    if (util.isMobile()) {
      window.open('/api/json/download?device=mobile', '_blank');
    } else {
      window.open('/api/json/download', '_blank');
    }
    setClickShowData(true)
  }

  const clickViewDataAction = (action) => {
    setClickShowData(true)
    setShowData(action)
  }

  const logoutSession = () => {
    api.logoutSession().then(() => ButtonDelay(navTo.introduction_1))
  }

  React.useEffect(() => {
    api.getCDRDashboardUrl().then((result) => {
      const { data } = result
      if (data && data.cdr_url) {
        setCdrDashboardUrl(data.cdr_url)
      }
    })
  })

  const HeroSection = () => {

    return (
      <>
        <ImageHolder alt='' src={CongratsHeroSvg} />
        <Flex flex="row" style={{ width: '100%' }}>
          {
            //  data is not finished and has error, display error message
            summaryData.isFinished === false || (summaryData.isFinished === true && summaryData.hasError === true) ?
              <ErrorBootstrapSection className="bootstrap" style={{ marginBottom: '0px', width: '100%' }}>
                <div className="alert alert-danger">Unfortunately, we weren't able to collect data from {bankTitle}</div>
              </ErrorBootstrapSection> : <>
                <ButtonGreen
                  style={{ marginRight: '12.5px' }}
                  onClick={() => ButtonDelay(downloadData)}
                >
                  Download PDF
                </ButtonGreen>
                {
                  (userDetail.data && userDetail.data?.isPro) ?
                    <ButtonGreen
                      style={{ marginLeft: '12.5px' }}
                      onClick={() => ButtonDelay(downloadJSONData, true)}
                    >
                      Download JSON
                    </ButtonGreen> :
                    <ButtonGreen
                      style={{ marginLeft: '12.5px' }}
                      onClick={() => ButtonDelay(clickViewDataAction, true)}
                    >
                      View data
                    </ButtonGreen>
                }
              </>
          }
        </Flex>
      </>
    )
  }

  const ButtonGroup = () => {
    return (
      <ButtonGreen style={{ marginTop: '24px' }} onClick={() => userDetail.data?.isPro ? ButtonDelay(navTo.dashboard) : logoutSession()}>Finish</ButtonGreen>
    )
  }

  // React query functions
  useQuery('progressSummary', async () => {
    const { data } = await api.getProgressSummary()
    return data
  }, {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5mins
    onSuccess: data => {
      let finalData = {}
      console.log('data: ', data);
      finalData.isFinished = data.isFinished
      finalData.hasError = data.hasError
      Object.keys(data).forEach(cluster => {
        const workingCluster = data[cluster]
        if (cluster === 'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS') {
          const address = workingCluster.organisationName ? workingCluster.address || {} : workingCluster.residentialAddress || {}
          finalData.personalDetails = {
            name: workingCluster.organisationName ? `${workingCluster.organisationName}${workingCluster.agentFirstName ? ` ( ${workingCluster.agentFirstName}` : ''}${workingCluster.agentLastName ? ` ${workingCluster.agentLastName} )` : ' )'}` : `${workingCluster.firstName ? workingCluster.firstName : '-'}${workingCluster.lastName ? ` ${workingCluster.lastName}` : ''}`,
            address: `${address?.addressLine1 ? address?.addressLine1 : '-'}${address.addressLine2 ? ' ' + address.addressLine2 : ''}${address.city ? ', ' + address.city + ',' : ''}${address.postcode ? ' ' + address.postcode : ''}${address.state ? ' ' + address.state : ''}`,
            state: address.state
          }
        }
        if (cluster === 'ACCOUNT_BALANCE_AND_DETAILS') {
          let data = []
          workingCluster.forEach((acc) => {
            data.push({
              name: `${acc.displayName} ${acc.nickname && acc.nickname.length > 0 ? `: "${acc.nickname}"` : ''}`,
              balance: parseFloat(acc.availableBalance)
            })
          })
          finalData.accountBalances = {
            data: data,
            total: data.reduce((prev, curr) => curr.balance + prev, 0)
          }
        }
        if (cluster === 'DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS') {
          let directDebitData = []
          let scheduledPaymentData = []
          workingCluster.directDebits.forEach((x) => {
            directDebitData.push({
              date: x.lastDebitDateTime,
              description: x.authorisedEntity?.description,
              amount: x.lastDebitAmount
            })
          })
          workingCluster.scheduledPayments.forEach((x) => {
            scheduledPaymentData.push({
              description: x.nickname,
              amount: x.paymentSet[0]?.amount,
              frequency: x.recurrence.recurrenceUType
            })
          })
          finalData.directDebits = {
            data: directDebitData,
            total: workingCluster.directDebitTotalCount
          }
          finalData.scheduledPayments = {
            data: scheduledPaymentData,
            total: workingCluster.scheduledPaymentsTotalCount
          }
        }
        if (cluster === 'TRANSACTION_DETAILS') {
          let txData = []
          workingCluster.transactions.forEach((x) => {
            txData.push({
              date: x.postingDateTime,
              description: x.description,
              amount: parseFloat(x.amount),
              status: x.status
            })
          })
          finalData.transactionDetails = {
            data: txData,
            total: workingCluster.totalCount
          }
        }
        if (cluster === 'SAVED_PAYEES') {
          // this is a work around for rab data
          const shouldMaskRABData = data.bank.dataHolderBrandId === '264db1e2-8e1d-eb11-a834-000d3a8842e1' ? true : false
          let payeesData = []
          payeesData.push({
            name: 'Aussie Payees',
            count: workingCluster.domestic > 0 ? workingCluster.domestic : (shouldMaskRABData ? '-' : 0)
          })
          payeesData.push({
            name: 'International Payees',
            count: workingCluster.international > 0 ? workingCluster.international : (shouldMaskRABData ? '-' : 0)
          })
          payeesData.push({
            name: 'Billers',
            count: workingCluster.biller > 0 ? workingCluster.biller : (shouldMaskRABData ? '-' : 0)
          })
          payeesData.push({
            name: 'Digital Wallets',
            count: workingCluster.digitalWallet > 0 ? workingCluster.digitalWallet : (shouldMaskRABData ? '-' : 0)
          })
          finalData.savedPayees = {
            data: payeesData,
            total: workingCluster.totalCount
          }
        }
      })
      setSummaryData(finalData);
      console.log('finalData: ', finalData);
    }
  })

  const Overlay = styled(props => <Flex flex="w100 h100" {...props} />)`
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: rgba(0,0,0,0.65);
  `

  return (
    <Layout.ColumnLayout title="Congrats!">
      <HeaderBanner logoUrl={bankUri} withStep={true}>
        <Breadcrumb from={6} to={6} />
      </HeaderBanner>
      {/* mobile only */}
      <Vis.MobileOnly flex="col">
        <WhiteSection flex="col" style={{ margin: '11px 10px 0px 10px' }}>
          <Vis.MobileOnly><Text.Steps>Step 6 of 6</Text.Steps></Vis.MobileOnly>
          <Text.H1 style={{ marginTop: '0px', marginBottom: '16px', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Congrats!</Text.H1>
          <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Here is your CDR data from <b>{bank?.brandName}</b>. You can now preview and download it</GreenBarSection>
        </WhiteSection>

        {
          showData === true
            ? (
              <Overlay style={{ zIndex: 50 }}>
                <WhiteSection flex="col hcenter shadow" style={{ background: 'white', marginTop: '15px', borderRadius: '9px 9px 0px 0px', padding: '18px' }}>
                  <Flex flex="col h100">
                    <ViewDataSection style={{ zIndex: 50 }} data={summaryData} bankTitle={bankTitle} logoUrl={bankUri} onDownload={() => downloadData()} onClose={() => setShowData(false)} />
                  </Flex>
                </WhiteSection>
              </Overlay>
            )
            : (
              <>
                <WhiteSection flex="col hcenter shadow" style={{ borderRadius: '9px', margin: '0px 20px', padding: '18px' }}>
                  <HeroSection />
                </WhiteSection>
                <WhiteSection flex="col" style={{ paddingTop: '30px' }}>
                  <ButtonGroup />
                  {
                    (clickedShowData || (summaryData.isFinished === true && summaryData.hasError === true)) && !userDetail.data?.isPro &&
                    <ButtonGreen onClick={() => {
                      api.getNext()
                      ButtonDelay(navTo.selectyourbank_5)
                    }}>Select another bank</ButtonGreen>
                  }
                </WhiteSection>

                <LightBlueSectionNoMargin flex="col text-center" style={{ padding: '50px 30px' }}>
                  <Text.H5 style={{ justifyContent: 'center', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}><span>Review this consent at anytime in our <div><a rel="noreferrer noopener" target="_blank" href={cdrDashboardUrl}>Consumer Dashboard</a></div></span></Text.H5>
                </LightBlueSectionNoMargin>
                <WhiteSection flex="col">
                  <FooterBanner />
                </WhiteSection>
              </>)
        }


      </Vis.MobileOnly>

      {/* Tablet */}

      <Vis.TabletOnly flex="col">
        <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
          <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Congrats!</Text.H1>
          <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Here is your CDR data from <b>{bank?.brandName}</b>. You can now preview and download it</GreenBarSection>
        </WhiteSection>
        <WhiteSection flex="col shadow" style={{ borderRadius: '9px', margin: '0px 60px', padding: '18px 11px' }}>
          {
            showData === true
              ? (
                <Flex flex="col" style={{ maxHeight: '80vh' }}>
                  <ViewDataSection data={summaryData} onDownload={() => downloadData()} onClose={() => setShowData(false)} />
                </Flex>)
              : (<HeroSection />)
          }
        </WhiteSection>
        <WhiteSection flex="col" style={{ padding: '70px 200px 0px 200px' }}>
          <ButtonGroup />
          {
            (clickedShowData || (summaryData.isFinished === true && summaryData.hasError === true)) && !userDetail.data?.isPro &&
            <ButtonGreen onClick={() => {
              api.getNext()
              ButtonDelay(navTo.selectyourbank_5)
            }}>Select another bank</ButtonGreen>
          }
        </WhiteSection>

        <LightBlueSectionNoMargin flex="col text-center" style={{ padding: '50px 130px' }}>
          <Text.H5 style={{ justifyContent: 'center', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}><span>Review this consent at anytime in our <div><a rel="noreferrer noopener" target="_blank" href={cdrDashboardUrl}>Consumer Dashboard</a></div></span></Text.H5>
        </LightBlueSectionNoMargin>
        <WhiteSection flex="col">
          <FooterBanner />
        </WhiteSection>
      </Vis.TabletOnly>

      {/* Desktop */}
      <Vis.DesktopOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
          <WhiteSection flex="col w50" style={{ padding: '0px' }}>
            <WhiteSection flex="col nogrow">
              <Text.H1 style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Congrats!</Text.H1>
              <GreenBarSection style={{ color: '#373737', fontFamily: 'Arial' }}>Here is your CDR data from <b>{bank?.brandName}</b>. You can now preview and download it</GreenBarSection>
            </WhiteSection>
            <WhiteSection flex="col nogrow" style={{ marginTop: "80px", padding: "0px 18px", width: '320px' }}>
              <ButtonGroup />
              {
                (clickedShowData || (summaryData.isFinished === true && summaryData.hasError === true)) && !userDetail.data?.isPro &&
                <ButtonGreen onClick={() => {
                  api.getNext()
                  ButtonDelay(navTo.selectyourbank_5)
                }}>Select another bank</ButtonGreen>
              }
            </WhiteSection>
            {/* Filler */}
            <Flex />
          </WhiteSection>


          <Flex flex="w50 col">
            <WhiteSection flex="col shadow nogrow" style={{ marginTop: '30px', paddingBottom: '41px', marginBottom: '30px', borderRadius: '13px' }}>
              {
                showData === true
                  ? (
                    <Flex flex="col nogrow" style={{ maxHeight: '75vh', overflow: 'hidden' }}>
                      <ViewDataSection data={summaryData} onDownload={() => downloadData()} onClose={() => setShowData(false)} />
                    </Flex>)
                  : (<HeroSection />)
              }
            </WhiteSection>
          </Flex>
        </WhiteSection>
        <LightBlueSectionNoMargin flex="col text-center" style={{ padding: '50px 130px' }}>
          <Text.H5 style={{ justifyContent: 'center', color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}><span>Review this consent at anytime in our <div><a rel="noreferrer noopener" target="_blank" href={cdrDashboardUrl}>Consumer Dashboard</a></div></span></Text.H5>
        </LightBlueSectionNoMargin>
        <FooterBanner />
      </Vis.DesktopOnly>

    </Layout.ColumnLayout>
  )
}

export default Congrats
